import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import "@/assets/css/tailwind.css"

const app = createApp(App)
.use(router);

app.directive('focus', {
  mounted(el, binding) {
    if (binding.value === undefined || binding.value) {
      el.focus();
    }
  },
});

app.directive('scrollto', {
  mounted(el, binding) {
    if (binding.value === undefined || binding.value) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  },
  updated(el, binding) {
    if (binding.value === undefined || binding.value) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  },
});

app.mount('#app');
